import router from '@/router'
import GridHelpers from '@/components/_core/GridsCore/helpers/GridHelpers'
import ExcelMiddleware from '@/components/_core/GridsCore/helpers/ExcelMiddleware'
let ExcelErrors = {
  styleColorChanged: false,
  filteringErrors: false,
  errorObject: {}, // defined in ImportSpreadsheetGrid / criticalErrorType
  errorObjectMessages: {
    // critical
    duplicate: {
      tooltip: 'Duplicate Style/Color',
      statusbar: 'Duplicate Style/Color'
    },
    duplicateSoft: {
      tooltip: 'Duplicate Style/Color',
      statusbar: 'Duplicate Style/Color'
    },
    inAssortment: {
      tooltip: 'This Style/Color is Already in Season/Year/Region',
      statusbar: 'Style/Color Already in Season/Year/Region'
    },
    already: {
      tooltip: 'This Style/Color is Already in Your Assortment',
      statusbar: 'Style/Color Already in Assortment'
    },
    stylecolor: {
      tooltip: 'Style/Color Not Found',
      statusbar: 'Style/Color Not Found'
    },
    productType: {
      tooltip: 'Product Type is not correct',
      statusbar: 'Incorrect Product Type'
    },
    notAllowed: {
      tooltip: 'Product is not allowed',
      statusbar: 'Product Not Allowed'
    },

    // warning
    status: {
      tooltip: 'This product does not match your assortment status',
      statusbar: 'Non-Matching Status'
    },
    locationId: {
      tooltip: 'This product is not available in your location',
      statusbar: 'Product Not Available'
    },

    // changed
    changed: {
      tooltip: '',
      statusbar: 'Data Differs From Excel file'
    },

    // sample inventory
    missingRowNumber: {
      tooltip: 'Missing Row Number',
      statusbar: 'Missing Row Number'
    },
    missingRequiredProperty: {
      tooltip: 'Missing Required Property',
      statusbar: 'Missing Required Property'
    },
    tooManySampleProperties: {
      tooltip: 'Too Many Sample Properties',
      statusbar: 'Too Many Sample Properties'
    },
    sampleExists: {
      tooltip: 'Sample Already Exists',
      statusbar: 'Sample Already Exists'
    },
    moldNumExists: {
      tooltip: 'Mold Number Already Exists',
      statusbar: 'Mold Number Already Exists'
    },
    unknown: {
      tooltip: 'Unknown error',
      statusbar: 'Unknown error'
    },
    notblank: {
      tooltip: 'Status, Side, Quantity, and Bin are Required',
      statusbar: 'Status, Side, Quantity, and Bin are Required'
    }
  },

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // INIT
  reinit () {
    const t = GridHelpers.mgStashThisArray[0]
    if (t?.type === 'sample-inventory-grid') {
      ExcelErrors.errorObjectMessages.stylecolor.tooltip = 'Sample Missing Value'
      ExcelErrors.errorObjectMessages.stylecolor.statusbar = 'Sample Missing Value'
      ExcelErrors.errorObjectMessages.duplicate.tooltip = 'Duplicate Sample'
      ExcelErrors.errorObjectMessages.duplicate.statusbar = 'Duplicate Sample'
      if (t?.subtype === 'Production') {
        ExcelErrors.errorObjectMessages.notblank.tooltip = 'Division, Style, Color, Status, Side, Quantity & Bin are Required'
        ExcelErrors.errorObjectMessages.notblank.statusbar = 'Division, Style, Color, Status, Side, Quantity & Bin are Required'
      } else if (t?.subtype === 'Sales') {
        ExcelErrors.errorObjectMessages.notblank.tooltip = 'Division, Style, Color, Status, Side, Quantity, Region, Aisle, Direction & Level are Required'
        ExcelErrors.errorObjectMessages.notblank.statusbar = 'Division, Style, Color, Status, Side, Quantity, Region, Aisle, Direction & Level are Required'
      }
    }
    ExcelErrors.filteringErrors = false
    ExcelErrors.styleColorChanged = false
    ExcelErrors.errorObject = {}
  },

  // CHANGING INFO - only for not-bulk-downloader
  checkForChanged () {
    ExcelErrors.errorObject.changed = false
    if (ExcelMiddleware.importGridThis.subtype === 'not-bulk-downloader') {
      let rows = GridHelpers.getRowNodes()
      for (let i = 0; i < rows.length; i++) {
        let node = rows[i]
        let gridColumns = ExcelMiddleware.importGridThis.gridApi.getColumns()
        // set changed
        if (node.data && node.data.origdata) {
          let doChanged = false
          for (let i = 0; i < gridColumns.length; i++) {
            let colId = gridColumns[i].colId
            if (node.data[colId] !== node.data.origdata[colId]) {
              // handle differently
              ExcelErrors.errorObject.changed = true
              doChanged = true
            }
          }
          node.data.changed = doChanged
        }
      }
    }
  },

  /// //////////////////////////////////
  // STATUS
  validateAndContinueLabel () {
    let ret = 'Validate Items'
    let type = ExcelErrors.criticalErrorType(true)
    switch (type) {
      case 'critical':
        ret = 'Critical Issues Fixed - Resubmit'
        break
      case 'warning':
        ret = (ExcelMiddleware.importGridThis.subtype === 'not-bulk-downloader') ? 'Ignore Warnings & Import' : 'Ignore Warnings & Download'
        break
      case 'passed':
        ret = (ExcelMiddleware.importGridThis.subtype === 'not-bulk-downloader') ? 'Import to Assortment' : 'Download Items'
        break
      case 'reset':
        ret = 'Revalidate Items'
        break
    }
    return ret
  },
  criticalErrorType (createNewErrorObject) {
    let type = 'start'
    // init ExcelErrors.errorObject
    if (createNewErrorObject) {
      let oldChanged = ExcelErrors.errorObject.changed
      ExcelErrors.errorObject = {
        // critical
        duplicate: false,
        duplicateSoft: false,
        already: false,
        inAssortment: false,
        stylecolor: false,
        notAllowed: false,
        productType: false,

        // warning
        status: false,
        locationId: false,

        // other
        anyErrors: false,

        // sample inventory
        missingRowNumber: false,
        missingRequiredProperty: false,
        tooManySampleProperties: false,
        sampleExists: false,
        moldNumExists: false,
        unknown: false,
        notblank: false
      }
      ExcelErrors.errorObject.changed = oldChanged
    }

    // set to passed
    if (ExcelMiddleware.clickedImportOnce) {
      type = 'passed'
    }

    // but check for errors
    let rows = GridHelpers.getRowNodes()
    for (let i = 0; i < rows.length; i++) {
      if (rows[i].data && rows[i].data.error && rows[i].data.error.errorSeverity) {
        // set the button label/action type
        if (rows[i].data.error.errorSeverity === 'critical') {
          type = 'critical'
        } else if (rows[i].data.error.errorSeverity === 'warning') {
          if (type !== 'critical') {
            type = 'warning'
          }
        }

        // also create a new error object
        if (createNewErrorObject) {
          switch (rows[i].data.error.errorType) {
            case 'duplicate':
              ExcelErrors.errorObject.duplicate = true
              ExcelErrors.errorObject.anyErrors = true
              break
            case 'duplicateSoft':
              ExcelErrors.errorObject.duplicateSoft = true
              ExcelErrors.errorObject.anyErrors = true
              break
            case 'already':
              ExcelErrors.errorObject.already = true
              ExcelErrors.errorObject.anyErrors = true
              break
            case 'inAssortment':
              ExcelErrors.errorObject.inAssortment = true
              ExcelErrors.errorObject.anyErrors = true
              break
            case 'style':
            case 'color':
            case 'stylecolor':
              ExcelErrors.errorObject.stylecolor = true
              ExcelErrors.errorObject.anyErrors = true
              break
            case 'notAllowed':
              ExcelErrors.errorObject.notAllowed = true
              ExcelErrors.errorObject.anyErrors = true
              break
            case 'productType':
              if (ExcelMiddleware.importGridThis.subtype === 'not-bulk-downloader') {
                ExcelErrors.errorObject.productType = true
                ExcelErrors.errorObject.anyErrors = true
              }
              break
            case 'status':
              ExcelErrors.errorObject.status = true
              ExcelErrors.errorObject.anyErrors = true
              break
            case 'locationId':
              ExcelErrors.errorObject.locationId = true
              ExcelErrors.errorObject.anyErrors = true
              break

            case 'missingRowNumber':
              ExcelErrors.errorObject.missingRowNumber = true
              ExcelErrors.errorObject.anyErrors = true
              break
            case 'missingRequiredProperty':
              ExcelErrors.errorObject.missingRequiredProperty = true
              ExcelErrors.errorObject.anyErrors = true
              break
            case 'tooManySampleProperties':
              ExcelErrors.errorObject.tooManySampleProperties = true
              ExcelErrors.errorObject.anyErrors = true
              break
            case 'sampleExists':
              ExcelErrors.errorObject.sampleExists = true
              ExcelErrors.errorObject.anyErrors = true
              break
            case 'moldNumExists':
              ExcelErrors.errorObject.moldNumExists = true
              ExcelErrors.errorObject.anyErrors = true
              break
            case 'unknown':
              ExcelErrors.errorObject.unknown = true
              ExcelErrors.errorObject.anyErrors = true
              break
            case 'notblank':
              ExcelErrors.errorObject.notblank = true
              ExcelErrors.errorObject.anyErrors = true
              break
          }
        }
      }
    }

    // reset
    if (ExcelErrors.styleColorChanged) {
      type = 'reset'
    }

    return type
  },

  inGridValidationCheck () {
    let t = GridHelpers.mgStashThisArray[0]
    let listOfBlankCellsThatGenerateErrors = []
    let alreadyInAssortment = []
    let duplicateStyleColors = []
    let styleColorInventory = []
    let duplicateStyleColorsFirstNode = []
    let rows = GridHelpers.getRowNodes()
    for (let i = 0; i < rows.length; i++) {
      // GET VALUES
      let node = rows[i]
      let styleColorId = ExcelErrors.getStyleColorId(node)
      let completelyBlankRow = ExcelErrors.isCompletelyBlankRow(node)
      let blankStyleColor = ExcelErrors.isBlankStyleColor(node)

      // FIRST: CLEAR OLD VALUES
      let clearOldValues = false
      if (node.data.error && (node.data.error.errorType === 'duplicate' || node.data.error.errorType === 'duplicateSoft')) {
        clearOldValues = true
      } else if (blankStyleColor) {
        clearOldValues = true
      }
      if (clearOldValues) {
        if (!node.data.error) {
          node.data.error = {}
        }
        node.data.error.errorMessage = ''
        node.data.error.errorType = ''
        node.data.error.errorSeverity = ''
      }

      // check if completely blank row
      if (!completelyBlankRow) {
        if (t?.type !== 'sample-inventory-grid' && blankStyleColor) {
          if (!node.data.error) {
            node.data.error = {}
          }
          node.data.error.errorMessage = ExcelErrors.errorObjectMessages.stylecolor.tooltip
          node.data.error.errorType = 'stylecolor'
          node.data.error.errorSeverity = 'critical'
        } else {
          // add to duplicate push
          if (!styleColorInventory[styleColorId]) {
            styleColorInventory[styleColorId] = true
            duplicateStyleColorsFirstNode[styleColorId] = node
          } else {
            duplicateStyleColors.push(node)
          }

          // SUBTYPE SPECIFIC: check to see if already in assortment
          if (ExcelMiddleware.importGridThis.subtype === 'not-bulk-downloader') {
            let already = []
            let products
            if (t?.type === 'sample-inventory-grid') {
              products = GridHelpers.getRowNodes()
              // products = GridHelpers.extractRowDataFromRowNodes(rowNodes)
              // already = products.filter(x => (x['style'] + '-' + x['color'] + '-' + x['side'] + '-' + x['size'] + '-' + x['status']) === styleColorId)
              if (t?.subtype === 'Production') {
                already = products.filter(x => (x.data['style'] + '-' + x.data['color'] + '-' + x.data['side'] + '-' + x.data['size'] + '-' + x.data['status'] + '-' + x.data['moldNum'] + '-' + x.data['moldDate'] + '-' + x.data['bin']) === styleColorId)
              } else if (t?.subtype === 'Sales') {
                already = products.filter(x => (x.data['style'] + '-' + x.data['color'] + '-' + x.data['side'] + '-' + x.data['size'] + '-' + x.data['status'] + '-' + x.data['moldNum'] + '-' + x.data['moldDate'] + '-' + x.data['region'] + '-' + x.data['aisle'] + '-' + x.data['direction'] + '-' + x.data['level']) === styleColorId)
              }
            } else {
              // TODO: do this for order details? We don't currently
              if (t?.type !== 'orders-detail') {
                products = ExcelMiddleware.importGridThis.assortment.products
                already = ExcelMiddleware.importGridThis.assortment.products.filter(x => (x['style'] + '-' + x['color']) === styleColorId)
              }
            }
            if (already.length > 0) {
              alreadyInAssortment.push(node)
            }
          }
        } // blankStyleColor
      } // completelyBlankRow

      // blanks not allowed in sample-inventory-grid
      if (t?.type === 'sample-inventory-grid') {
        // if ANY cell is blank, but not COMPLETELY blank
        // if (ExcelErrors.isAnyCellBlankInRow(node, ['moldNum', 'moldDate'])) { //old way
        if (!completelyBlankRow) {
          if (t?.subtype === 'Production') {
            if (!node.data.division || !node.data.style || !node.data.color || !node.data.status || !node.data.side || !node.data.totalQty || !node.data.bin) { // only Division, Sample Status, Side, Quantity, Bin required
              listOfBlankCellsThatGenerateErrors.push(node)
            }
          } else if (t?.subtype === 'Sales') {
            if (!node.data.division || !node.data.style || !node.data.color || !node.data.status || !node.data.side || !node.data.totalQty || !node.data.region || !node.data.aisle || !node.data.direction || !node.data.level) { // only Division, Sample Status, Side, Quantity, region, aisle, direction, level required
              listOfBlankCellsThatGenerateErrors.push(node)
            }
          } // subtypes
        } // !completelyBlankRow
      } // type === 'sample-inventory-grid'
    } // master row loop

    // check dupes
    // dont do this for ITS__ORDERS__SAMPLE__DETAIL
    if (router.currentRoute.value.meta.manageType !== ITS__ORDERS__SAMPLE__DETAIL) {
      for (let i = 0; i < duplicateStyleColors.length; i++) {
        // add the dupes
        let node = duplicateStyleColors[i]
        if (!node.data.error && !node.data.error.errorType) {
          node.data.error = {}
        }
        let duplicateType = 'duplicateSoft'
        let duplicateSeverity = 'warning'

        if (ExcelMiddleware.importGridThis.subtype === 'not-bulk-downloader') {
          duplicateType = 'duplicate'
          duplicateSeverity = 'critical'
        }
        node.data.error.errorMessage = ExcelErrors.errorObjectMessages[duplicateType].tooltip
        node.data.error.errorType = duplicateType
        node.data.error.errorSeverity = duplicateSeverity

        // also set the first node, since that would have been skipped,
        let styleColorId = ExcelErrors.getStyleColorId(node)
        node = duplicateStyleColorsFirstNode[styleColorId]
        if (!node.data.error) {
          node.data.error = {}
        }
        node.data.error.errorMessage = ExcelErrors.errorObjectMessages[duplicateType].tooltip
        node.data.error.errorType = duplicateType
        node.data.error.errorSeverity = duplicateSeverity
      }
    }

    // check to see if it is already added into the grid, also not blanks
    if (ExcelMiddleware.importGridThis.subtype === 'not-bulk-downloader') {
      // already in
      for (let i = 0; i < alreadyInAssortment.length; i++) {
        // add the dupes
        let node = alreadyInAssortment[i]
        if (!node.data.error) {
          node.data.error = {}
        }
        node.data.error.errorMessage = ExcelErrors.errorObjectMessages.already.tooltip
        node.data.error.errorType = 'already'
        node.data.error.errorSeverity = 'critical'
      }

      // not blanks
      for (let i = 0; i < listOfBlankCellsThatGenerateErrors.length; i++) {
        // add the dupes
        let node = listOfBlankCellsThatGenerateErrors[i]
        if (!node.data.error) {
          node.data.error = {}
        }
        node.data.error.errorMessage = ExcelErrors.errorObjectMessages.notblank.tooltip
        node.data.error.errorType = 'notblank'
        node.data.error.errorSeverity = 'critical'
      }
    }
  },
  isCompletelyBlankRow (node) {
    /* I don't trust this logic but keeping it for a second - 9/21/2022
    // if no style AND no color, loop through each cell
    // if they are all blank, then this is a blank row, and skip it
    let blankRow = false
    let blankStyleColor = ExcelErrors.isBlankStyleColor(node)
    if (blankStyleColor) {
      blankRow = true
      for (let ii = 0; ii < ExcelMiddleware.agGridColumns.length; ii++) {
        let col = ExcelMiddleware.agGridColumns[ii]
        let colField = col.value
        if (node.data[colField] !== undefined && node.data[colField] !== '') {
          blankRow = false
        }
      }
    }
     */
    let blankRow = true
    for (let ii = 0; ii < ExcelMiddleware.agGridColumns.length; ii++) {
      let col = ExcelMiddleware.agGridColumns[ii]
      let colField = col.value
      if (node.data[colField] !== undefined && node.data[colField] !== '') {
        blankRow = false
      }
    }
    return blankRow
  },
  // returns true if isAnyCellBlankInRow - but skips cells in skips
  isAnyCellBlankInRow (node, skips = []) {
    let blankCell = false
    for (let ii = 0; ii < ExcelMiddleware.agGridColumns.length; ii++) {
      let col = ExcelMiddleware.agGridColumns[ii]
      let colField = col.value
      if (node.data[colField] === undefined || node.data[colField] === '') {
        if (!skips.includes(colField)) { // make sure it's not in skips
          blankCell = true
        }
      }
    }
    return blankCell
  },
  doStyleColorRecheck (params) {
    let t = GridHelpers.mgStashThisArray[0]
    let ret
    if (t?.type === 'sample-inventory-grid') {
      ret = (params.colDef.field === 'style' || params.colDef.field === 'color' || params.colDef.field === 'side' || params.colDef.field === 'size' || params.colDef.field === 'status')
    } else {
      ret = (params.colDef.field === 'style' || params.colDef.field === 'color')
    }
    return ret
  },
  isBlankStyleColor (node) {
    let t = GridHelpers.mgStashThisArray[0]
    if (t?.type === 'sample-inventory-grid') {
      return !node.data.style || !node.data.color || !node.data.size || !node.data.side || !node.data.status || !node.data.moldNum || !node.data.moldDate || !node.data.bin
    } else {
      return !node.data.style && !node.data.color
    }
  },
  getStyleColorId (node) {
    let t = GridHelpers.mgStashThisArray[0]
    let ret = ''
    if (t?.type === 'sample-inventory-grid') {
      ret = node.data.style + '-' + node.data.color + '-' + node.data.size + '-' + node.data.side + '-' + node.data.status + '-' + node.data.moldNum + '-' + node.data.moldDate + '-' + node.data.bin
    } else {
      ret = node.data.style + '-' + node.data.color
    }
    return ret
  },

  /// //////////////////////////////////
  // TOGGLE CRITICAL ERRORS
  async showItemsJustCriticalErrors () {
    if (ExcelMiddleware.importGridThis?.gridApi) {
      let filterKey = 'error.errorSeverity'
      let gridApi = ExcelMiddleware.importGridThis.gridApi
      let filterInstance = await gridApi.getColumnFilterInstance(filterKey)
      if (filterInstance) {
        await gridApi.setColumnFilterModel(filterKey, {
          type: 'contains', // https://www.ag-grid.com/javascript-grid-filter-text/
          filter: 'critical'
        })
        // filterInstance.applyModel()
        gridApi.onFilterChanged()
        GridHelpers.deselectAll()
      }
    }
  },
  async showItemsAll () {
    if (ExcelMiddleware.importGridThis?.gridApi) {
      ExcelErrors.filteringErrors = false
      let filterKey = 'error.errorSeverity'
      let gridApi = ExcelMiddleware.importGridThis.gridApi
      let filterInstance = await gridApi.getColumnFilterInstance(filterKey)
      if (filterInstance?.appliedModel?.filter) {
        await gridApi.setColumnFilterModel(filterKey,null)
        // filterInstance.applyModel()
        gridApi.onFilterChanged()
        GridHelpers.deselectAll()
      }
    }
  }
}
export default ExcelErrors
