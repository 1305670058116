import { generateInternalAssortmentName } from '@/helpers/assortmentHelper'
import GridHelpers from '@/components/_core/GridsCore/helpers/GridHelpers'
import PropertiesLookupLists from '@/components/_core/GridsCore/helpers/PropertiesLookupLists'
import properties from '@/store/modules/config/properties'
import BigNumber from 'bignumber.js'
import dayjs from 'dayjs'
import uniqBy from 'lodash/uniqBy'
import sortBy from 'lodash/sortBy'
import union from 'lodash/union'
import cloneDeep from 'lodash/cloneDeep'
import router from '@/router'
import shared from 'skch_its_be_fe_shared'

import {
  VUEX_ASSORTMENT_GRID_SETTINGS_CUSTOMSORT_STATE_UPDATE,
  VUEX_GRID_CONFIRMATION_CHANGE_INTERNAL_STATUS
} from '@/store/constants/models/assortments'

import {
  VUEX_ROUTING_ROUTE
} from '@/store/constants/routing'
import DataMiddleware from '@/components/_core/GridsCore/helpers/DataMiddleware'

const formatDateOnly = shared.dates.formatDateOnly
const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone')
const pricesheet = shared.pricesheet
const dateOnlyFromJsDate = shared.dates.dateOnlyFromJsDate

// FORMATTERS
let ColumnHelpers = {
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // setup
  eButtonTimer: null,
  customComparatorLists: [],

  resetConfig: function () {
    ColumnHelpers.customComparatorLists = []
  },

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // FILTER
  filterParams: function () {
    return {
      filterOptions: ['contains', 'notContains'],
      textMatcher: function (filter) {
        let filterTextLoweCase = filter?.filterText.toLowerCase()
        let valueLowerCase = filter?.value.toString().toLowerCase()

        function contains (target, lookingFor) {
          if (target === null) return false
          return target.indexOf(lookingFor) >= 0
        }

        let literalMatch = contains(valueLowerCase, filterTextLoweCase)
        return literalMatch
      },
      buttons: ['reset'],
      debounceMs: 0,
      maxNumConditions: 1
    }
  },
  filterParamsExact: function () {
    return {
      filterOptions: ['equals'],
      textMatcher: function (filter) {
        let filterTextLoweCase = filter?.filterText.toLowerCase()
        let valueLowerCase = filter?.value.toString().toLowerCase()
        let literalMatch = valueLowerCase === filterTextLoweCase
        return literalMatch
      },
      buttons: ['reset'],
      debounceMs: 0,
      maxNumConditions: 1
    }
  },
  filterParamsLibrariesName: function () {
    return {
      textMatcher: function (filter) {
        let filterTextLoweCase = filter?.filterText.toLowerCase()
        let valueLowerCase = filter?.value.toString().toLowerCase()

        function contains (target, lookingFor) {
          if (target === null) return false
          return target.indexOf(lookingFor) >= 0
        }

        return contains(valueLowerCase, filterTextLoweCase)
      },
      filterOptions: ['contains'],
      buttons: ['reset'],
      debounceMs: 800,
      caseSensitive: false,
      maxNumConditions: 1
    }
  },
  filterParamsLibraries: function (fieldOverride = '') {
    // if we need to go further custom, details here:
    // https://www.ag-grid.com/javascript-grid-filter-component/#custom-filter-example

    // override field in case the names don't line up
    return {
      textMatcher: function (filter) {
        let filterTextLoweCase = filter?.filterText.toLowerCase()
        let valueLowerCase = filter?.value.toString().toLowerCase()

        function contains (target, lookingFor) {
          if (target === null) return false
          return target.indexOf(lookingFor) >= 0
        }

        return contains(valueLowerCase, filterTextLoweCase)
      },
      filterOptions: ['contains'],
      buttons: ['reset'],
      debounceMs: 800,
      caseSensitive: false,
      maxNumConditions: 1,
      suppressSorting: true,
      values: (params) => {
        let ret = PropertiesLookupLists.getPropertiesLookupList(params.colDef.field, false, true)
        if (ret && ret.length > 0) {
          if (typeof ret[0] === 'object') {
            for (let i = 0; i < ret.length; i++) {
              ret[i] = ret[i].value
            }
          }
        } else {
          ret = []
        }
        ret.unshift(null) // add a blank at the top
        params.success(ret)
      },
      valueFormatter: function (params) {
        let ret = '(Blanks)'
        if (params.value) {
          ret = params.value
          let vals = PropertiesLookupLists.getPropertiesLookupList(params.colDef.field, false, true);
          if (vals && vals.length > 0) {
            if (typeof vals[0] === 'object') {
              let matchedItem = vals.find(item => item.value === params.value)
              if (matchedItem) {
                if (matchedItem?.label) {
                  ret = matchedItem?.label
                }
              }
            }
          }
        }
        return String(ret)
      }
    } //return
  }, // function

  filterParamsDate: function () {
    // if we need to go further custom, details here:
    // https://www.ag-grid.com/javascript-grid-filter-component/#custom-filter-example

    // override field in case the names don't line up
    return {
      closeOnApply: true,
      browserDatePicker: true,
      maxNumConditions: 1,
      buttons: ['reset'],
      filterOptions: ['equals'],
      comparator: (filterLocalDateAtMidnight, cellValue) => {
        let dateAsString = cellValue
        if (dateAsString == null) return -1
        dateAsString = String(dateAsString).substring(0, 10) // strip out any potential time zone
        let dateParts = dateAsString.split('/')
        if (dateParts.length < 3) {
          dateParts = dateAsString.split('-')
        }
        let yyyy = Number(dateParts[2])
        let mm = Number(dateParts[1]) - 1
        let dd = Number(dateParts[0])
        if (yyyy < 1000) {
          yyyy = Number(dateParts[0])
          mm = Number(dateParts[1]) - 1
          dd = Number(dateParts[2])
        }
        let cellDate = new Date(yyyy, mm, dd)

        if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
          return 0
        }

        if (cellDate < filterLocalDateAtMidnight) {
          return -1
        }

        if (cellDate > filterLocalDateAtMidnight) {
          return 1
        }
        return 0
      }
    }
  },

  getAllFilters () {
    let ret = {
      activeFilters: {},
      activeFiltersLongDescription: '',
      activeFiltersShortDescription: '',
      items: []
    }
    if (GridHelpers.isGridReady()) {
      const gridApi = GridHelpers.mgThisArray[0].gridApi
      const filterModel = gridApi.getFilterModel()
      if (filterModel) {
        for (let key in filterModel) {
          let filterObj = filterModel[key]
          if (filterObj) {
            let desc = ''
            if (filterObj.filterType === 'set') {
              ret.activeFilters[key] = filterObj.values
              desc = ColumnHelpers.getterArrayExplode(ret.activeFilters[key])
            } else if (filterObj.filterType === 'date') {
              let formattedDate = dateOnlyFromJsDate(filterObj.dateFrom)
              ret.activeFilters[key] = formattedDate
              desc = ret.activeFilters[key]
            } else if (filterObj.filterType === 'typeAhead') {
              ret.activeFilters[key] = filterObj.value
              desc = ret.activeFilters[key]
            } else {
              ret.activeFilters[key] = filterObj.filter
              desc = ret.activeFilters[key]
            }
            if (ret.activeFiltersLongDescription !== '') {
              ret.activeFiltersLongDescription += ', '
              ret.activeFiltersShortDescription += ', '
            }

            let lbl = GridHelpers.getColumnNameFromID(key)
            ret.activeFiltersLongDescription += lbl + ': ' + desc
            ret.activeFiltersShortDescription += lbl
            ret.items.push({
              activeFiltersLongDescription: ret.activeFiltersLongDescription,
              activeFiltersShortDescription: ret.activeFiltersShortDescription,
              key: key,
              label: lbl,
              values: filterObj.values
            })
          }
        }
      }
    }
    return ret
  },

  // this is called from the column header
  // synchronizes the column headers of multiple grids on a page
  // synchronizes input field
  async gridFilterChanged (e) {
    // get value of current filtered field
    const t = GridHelpers.mgThisArray[0]
    const keys = ColumnHelpers.getGridFilterColumnKeys(t)
    const gridApi = e.api // current

    let coldIdsThatChanged = []
    if (e.columns.length) {
      for (let i = 0; i < e.columns.length; i++) {
        coldIdsThatChanged.push(e.columns[i].colId)
      }
    }

    // this part will clear all unchecked filters
    /*
    const filters = ColumnHelpers.getAllFilters()
    const items = filters.items
    for (let i = 0; i < items.length; i++) {
      const item = items[i]
      const key = item.key
      const values = item.values
      if (values.length===0) {
        ColumnHelpers.clearFilterKey(key)
      }
    }
     */

    // get value of keys
    let theValue = null
    for (let i = 0; i < keys.length; i++) {
      let filterInstance = await gridApi.getColumnFilterInstance(keys[i])
      if (filterInstance?.appliedModel?.filter) {
        theValue = filterInstance.appliedModel.filter
      }
    } // keys

    // now set the value to all grids columns + the input field
    for (let z = 0; z < GridHelpers.mgThisArray.length; z++) {
      let t = GridHelpers.mgThisArray[z]
      // column
      for (let i = 0; i < keys.length; i++) {
        ColumnHelpers.setFilterColumn(keys[i], theValue, t)
      }

      // filter bar input field
      if (t.$refs['field_filter']) {
        t.field_filter = (theValue) || ''
      } else {
        t.field_filter = ''
      }
    }

    t.togglerfilterBar = Math.random()

    // purge server side
    if (GridHelpers.mgThisArray[0].rowModelType === 'serverSide') {
      gridApi.refreshServerSideStore({ purge: true })
    }

    // REFRESH ALL THE FILTERS BECAUSE THE VALUES MIGHT HAVE CHANGES
    const cols = gridApi.getColumns()
    for (let i = 0; i < cols.length; i++) {
      let col = cols[i]
      // const filterModel = gridApi.getFilterModel()
      if (col) {
        // https://www.ag-grid.com/javascript-data-grid/filter-set-api/
        // don't update the current model, because if it is open wheile you are looking at it it gets wonky
        // if (!coldIdsThatChanged.includes(col.colId)) {
        // ag-header-cell ag-header-cell-sortable ag-focus-managed ag-header-cell-filtered ag-column-menu-visible
        const colSelector = `.ag-header-cell[col-id='${col.colId}'].ag-column-menu-visible`
        let isColumnOpen = document.querySelector(colSelector)
        if (isColumnOpen === null) { // if it is not open, refresh value
          const filterInstance = await gridApi.getColumnFilterInstance(col.colId)
          if (filterInstance && filterInstance.refreshFilterValues) {
            // filterInstance.refreshFilterValues()
          }
        }
        // }
      }
    }
  },

  async setFilterColumn (columnKey, newValue, t) {
    if (t.gridApi) {
      let filterInstance = await t.gridApi.getColumnFilterInstance(columnKey)
      let currentValue = (filterInstance?.appliedModel?.filter) ? filterInstance.appliedModel.filter : null
      if (currentValue !== newValue) {
        if (newValue) {
          // main documentation: https://www.ag-grid.com/javascript-grid-filter-text/
          // help on arrays: https://github.com/ag-grid/ag-grid/issues/2256
          if (Array.isArray(newValue)) {
            await t.gridApi.setColumnFilterModel(columnKey, {
              filterType: 'text',
              type: 'startsWith',
              filter: 'abc',
            });
            await t.gridApi.setColumnFilterModel(columnKey, {
                type: 'set',
                values: newValue
            }).then(function () {
              // filterInstance.applyModel()
              t.gridApi.onFilterChanged()
              t.togglerfilterBar = Math.random()
            })
          } else {
            await t.gridApi.setColumnFilterModel(columnKey, {
              type: 'equals',
              filter: newValue
            }).then(function () {
              // filterInstance.applyModel()
              t.gridApi.onFilterChanged()
              t.togglerfilterBar = Math.random()
            })
          }
        } else {
          ColumnHelpers.clearFilterInstance(filterInstance, t, columnKey)
        }
      }
    }
  },

  getGridFilterColumnKeys (t) {
    let keys = []
    keys = [] // never used
    if (t.type === 'assortments-list') {
      if (t.$options.name === 'QuickGrid') {
        keys = ['title']
      } else if (t.$options.name === 'InternalAssortmentsListGrid') {
        keys = ['lookupFilter']
      } else {
        keys = ['ag-Grid-AutoColumn']
      }
    } else if (t.type === 'orders-list') {
      keys = ['orderIdentifier']
    } else if (t.$options.name === 'LibrariesGrid') {
      keys = ['name']
    }
    return keys
  },

  gridFilterClearClick () {
    setTimeout(function () {
      ColumnHelpers.gridFilter()
    }, 100)
  },

  // this is called from the input field
  // synchronizes the column headers
  // handles multiple grids on a page
  gridFilter () {
    // first get input, can occur from multiple grids input fields and we don't know which
    let inputVal = null
    for (let z = 0; z < GridHelpers.mgThisArray.length; z++) {
      const t = GridHelpers.mgThisArray[z]
      if (t.field_filter) {
        inputVal = t.field_filter
      } else if (t.$refs['field_filter'] && t.$refs['field_filter'].$refs && t.$refs['field_filter'].$refs.input) {
        inputVal = t.$refs['field_filter'].$refs.input.value
      }
    }

    // now send filter to all grid column headers
    for (let z = 0; z < GridHelpers.mgThisArray.length; z++) {
      const t = GridHelpers.mgThisArray[z]
      const keys = ColumnHelpers.getGridFilterColumnKeys(t)
      for (let i = 0; i < keys.length; i++) {
        ColumnHelpers.setFilterColumn(keys[i], inputVal, t)
      }
    }// for this
  },

  clearFilterKey (key) {
    const t = GridHelpers.mgThisArray[0];
    t.gridApi.getColumnFilterInstance(key, (filterComponent) => {
      if (filterComponent) {
        filterComponent.setModel(null); // Clear the filter
        t.gridApi.onFilterChanged();
      } else {
        console.warn(`Filter component for key "${key}" is not available.`);
      }
    });
  },

  async clearFilterInstance (filterInstance, t, filterKey) {
    await t.gridApi.setColumnFilterModel(filterKey,null)
    t.gridApi.onFilterChanged()
    t.togglerfilterBar = Math.random()
  },

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // valueParsers
  valueParserBigNumberPassthrough: function (params) {
    return ColumnHelpers.numberRemoveExtraneous(params.newValue)
  },
  valueParserBigNumberPassthroughForceZero: function (params) {
    let x = ColumnHelpers.valueParserBigNumberPassthrough(params)
    if (x === '') {
      x = 0
    }
    return x
  },
  valueParserUpperCase: function (params) {
    return String(params.newValue).toUpperCase().trim()
  },
  valueParserTrim: function (params) {
    return String(params.newValue).trim()
  },

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // valueSetter
  valueSetterUppercase: function (params) {
    const str = String(params.newValue)
    let colId = params.colDef.field
    params.data[colId] = str.toUpperCase()
    return true
  },

  // if it has a space, reject the value outright
  valueSetterRejectSpaces: function (params) {
    const str = String(params.newValue)
    if (!str.includes(' ')) {
      let colId = params.colDef.field
      params.data[colId] = params.newValue
      return true
    } else {
      return false
    }
  },
  // no blanks allowed - simple, straight forward - no fancy publishing views
  valueSetterNotClearable: function (params) {
    // now check to see blank
    let doIt = true
    let val = params.newValue
    if (!val) {
      doIt = false
    } else {
      val = String(params.newValue)
      if (val) val = val.trim()
      if (!val) {
        doIt = false
      }
    }

    if (doIt) {
      let colId = params.colDef.field
      params.data[colId] = params.newValue
      return true
    } else {
      return false
    }
  },
  valueSetterNumberNotClearable: function (params) {
    // now check to see blank
    let doIt = true
    let val = params.newValue
    val = Number(val)
    if (params.newValue === '' || isNaN(val) || val <= 0) {
      doIt = false
    }

    if (doIt) {
      let colId = params.colDef.field
      params.data[colId] = params.newValue
      return true
    } else {
      return false
    }
  },
  valueSetterIsClearableSimple: function (params) {
    // allow blanks in pending view
    let allowBlanks = true
    if (GridHelpers.mgThisArray[0].isLibrariesGrid) {
      if (!GridHelpers.isLibraryPendingView()) {
        allowBlanks = !GridHelpers.stopColumnFromClear(params.column.colDef.field)
      }
    }

    // now check to see blank
    let doIt = true
    let val = params.newValue
    if (val) val = val.trim()
    if (!val) {
      if (!allowBlanks) {
        doIt = false
      }
    }
    if (doIt) {
      ColumnHelpers.valueSetterInListFinal(params, params.column.colDef.field, val)
    }
    return doIt
  },
  valueSetterIsNotClearableInList: function (params) {
    return ColumnHelpers.valueSetterInList(params, false)
  },
  valueSetterIsClearableInList: function (params) {
    // allow blanks in pending view
    let allowBlanks = true
    let colId = params.colDef.field
    if (GridHelpers.mgThisArray[0].isLibrariesGrid) {
      if (!GridHelpers.isLibraryPendingView()) {
        allowBlanks = !GridHelpers.stopColumnFromClear(colId)
      }
    }
    return ColumnHelpers.valueSetterInList(params, allowBlanks)
  },
  valueSetterNotClearableInList: function (params) {
    return ColumnHelpers.valueSetterInList(params, false)
  },
  valueSetterInList: function (params, allowBlanks) {
    // allow blanks or value in list
    let colId = params.colDef.field
    let list = PropertiesLookupLists.getPropertiesLookupList(colId, false, false, params)
    let listLowerCase = []
    if (list) {
      for (let i = 0; i < list.length; i++) {
        let tval = list[i]
        if (typeof tval === 'string' || tval instanceof String) {
          tval = tval.toLowerCase()
        }
        listLowerCase.push(tval)
      }
    }
    let val = ''
    if (params.newValue) {
      let newValue = params.newValue
      if (Array.isArray(newValue)) {
        val = newValue
      } else {
        val = (typeof newValue.trim === 'function') ? newValue.trim() : newValue
      }
    }

    // check for data types - weird issues where a number can become text.
    // so we need to be 100% over-redundant and check data type situations and pull the original list values if I can infer a match
    // note: same logic is in group edit multi field
    if (list && list.length) {
      for (let i = 0; i < list.length; i++) {
        let tmpLookupVal = String(list[i])
        let tempValue = String(val)
        if (tempValue === tmpLookupVal) {
          val = list[i] // this will get the ORIGINAL typeof from the list, as opposed to how it got translated
        } else {
          if (typeof list[i] === 'number') {
            let tmpLookupVal2 = Number(list[i])
            let tempValue2 = Number(val)
            if (tempValue2 === tmpLookupVal2) {
              val = list[i] // this will get the ORIGINAL typeof from the list, as opposed to how it got translated
            }
          }
        }
      }
    }

    // convert to array if multiselct
    if (params.column.colDef && params.column.colDef.cellEditorParams && params.column.colDef.cellEditorParams.multipleSelect) {
      if (Array.isArray(val) && val.length === 1) {
        val = val[0].split(',')
      } else if (!Array.isArray(val)) {
        val = val.split(',')
      }
    }

    // check for a lower case version
    let valLower = null
    let arrayValueIndex = -1
    if (val && !Array.isArray(val)) {
      valLower = (typeof val.toLowerCase === 'function') ? val.toLowerCase() : val
      arrayValueIndex = listLowerCase.indexOf(valLower)
    }

    if (val === '' || (Array.isArray(val) && val.length === 0)) {
      // ALLOW BLANKS
      if (allowBlanks) {
        ColumnHelpers.valueSetterInListFinal(params, colId, val)
        return true
      } else {
        return false
      }
    } else if (Array.isArray(val)) {
      // IF AN ARRAY OF VALUES, CHECK EACH ARRAY
      let finalVal = []
      val.forEach(function (valCheck, index) {
        // check for a lower case version
        let valCheckLower = valCheck.toLowerCase().trim()

        // try array and also properties
        let arrayValueIndex = listLowerCase.indexOf(valCheckLower)
        if (arrayValueIndex > -1) {
          let valueNormalized = list[arrayValueIndex]
          if (allowBlanks) {
            finalVal.push(valueNormalized)
          } else {
            if (valCheck) {
              finalVal.push(valueNormalized)
            }
          }
          finalVal.push(valueNormalized)
        } else {
          let isAValue = list.filter(function (x) {
            if (typeof x === 'object') {
              x = x?.value
            }
            if (typeof x === 'string' || x instanceof String) {
              return (x.toLowerCase() === valCheckLower)
            } else {
              return (x === val)
            }
          })
          // let isAValue = list.filter(x => x['value'].toLowerCase() === valCheckLower)
          if (isAValue.length > 0) {
            let valueNormalized = isAValue[0]
            valueNormalized = (valueNormalized.value) ? (valueNormalized.value) : valueNormalized
            if (allowBlanks) {
              finalVal.push(valueNormalized)
            } else {
              if (valCheck) {
                finalVal.push(valueNormalized)
              }
            }
          }
        }
      })
      // no dupes
      finalVal = [...new Set(finalVal)]

      // only do if there is something to set
      if (allowBlanks) {
        // set final value
        ColumnHelpers.valueSetterInListFinal(params, colId, finalVal)
        return true
      } else {
        // set final value
        if (finalVal.length > 0) {
          ColumnHelpers.valueSetterInListFinal(params, colId, finalVal)
          return true
        } else {
          return false
        }
      }
    } else if (arrayValueIndex > -1) {
      let valueNormalized = list[arrayValueIndex]

      // JUST SET NORMAL VALUE
      ColumnHelpers.valueSetterInListFinal(params, colId, valueNormalized)
      return true
    } else {
      // let isAValue = list.filter(x => x['value'].toLowerCase() === valLower)
      if (list) {
        let isAValue = list.filter(function (x) {
          if (typeof x === 'object') {
            x = x?.value
          }
          if (typeof x === 'string' || x instanceof String) {
            return (x.toLowerCase() === valLower)
          } else {
            return (x === val)
          }
        })

        if (isAValue.length > 0) {
          let valueNormalized = isAValue[0]
          valueNormalized = (valueNormalized.value) ? (valueNormalized.value) : valueNormalized
          ColumnHelpers.valueSetterInListFinal(params, colId, valueNormalized)
          return true
        }
      }

      // NOPE
      return false
    }
  },
  valueSetterInListFinal (params, colId, val) {
    if (colId.indexOf('primaryFile') > -1) {
      let filesField = colId.replace('primaryFile.', '')
      params.data.primaryFile[filesField] = val
    } else {
      params.data[colId] = val
    }
  },

  // FE protection against using same title/Division Name for any assortment within season/year/region/Division group
  // Both "Division group" and "title/Division Name" use this function
  // column titles:
  //  lookupFilter:   season/year/region
  //  hierarchy.0:        Division Group
  //  title:              Division Name
  valueSetterUniqueInternalAssortmentListName: function (params) {
    let ret = false
    if (GridHelpers.isGridReady()) {
      const colId = params.colDef.field
      const proposedObj = {
        season: params.data.season,
        year: params.data.year,
        region: params.data.region
      }

      // is this group or title
      let proposedGroup = params.data.gender
      let proposedTitle = params.newValue
      if (colId === 'gender') {
        proposedGroup = params.newValue
        proposedTitle = params.data.title
      }

      // check if existing
      const proposedMainName = generateInternalAssortmentName(proposedObj)
      const t = GridHelpers.mgThisArray[0]
      let rows = t.rowDataBoundMasterSet
      const proposedId = ''// always force check
      const proposedNameExists = GridHelpers.checkIfInternalAssortmentsProposedNameExists(proposedMainName, proposedGroup, proposedTitle, proposedId, rows)

      if (!proposedNameExists) {
        // update the title
        ret = true
        if (colId === 'gender') {
          params.data.hierarchy = [
            params.data.region,
            params.newValue
          ]
        } else {
          params.data[colId] = params.newValue
        }
      }
    }
    return ret
  },

  valueSetterMakeNumericFromCalendar: function (params) {
    if (!params.node?.group) {
      const colId = params.colDef.field
      let val = null
      if (params.newValue) {
        val = Number(params.newValue)
        if (isNaN(val)) {
          val = new Date(params.newValue)
          val = dateOnlyFromJsDate(val)
        }
      }
      params.data[colId] = val
    }
    return true
  },
  valueSetterMakeNumericFromCalendarNotBlank: function (params) {
    if (!params.node?.group) {
      const colId = params.colDef.field
      let val = null
      if (params.newValue) {
        val = Number(params.newValue)
        if (isNaN(val)) {
          val = new Date(params.newValue)
          val = dateOnlyFromJsDate(val)
        }
        params.data[colId] = val
      } else {
        return false
      }
    }
    return true
  },
  valueSetterStatusAreYouSurePrompt: function (params) {
    // check if it already went through dialogue and is coming from valueSetterStatusAreYouSurePrompt2 (aka, begins with the [[ALLOWED]] tag)
    let val = String(params.newValue)
    let isConfirmed = val.includes('[[ALLOWED]]', val)
    if (isConfirmed) {
      val = val.replace('[[ALLOWED]]', '')
      params.data.state = val
      return true
    } else {
      // check if value is allowed in dropdown
      let isValidValue = ColumnHelpers.valueSetterIsNotClearableInList(params)
      if (isValidValue) {
        // if allowed, prompt for confirmation
        let actionObj = {
          checkChangedFields: false,
          title: 'Are You Sure?',
          description: 'Changing the status will impact visibility to end users.',
          dispatchAction: VUEX_GRID_CONFIRMATION_CHANGE_INTERNAL_STATUS,
          dispatchActionObject: {
            id: params.data.id,
            newValue: val
            // node: params.node
          }
        }
        GridHelpers.mgThisArray[0].$store.dispatch('VUEX_GLOBAL_ACTION_PROMPT_SHOW', { actionObj: actionObj })
      }
      params.data.state = params.oldValue
      return false
    }
  },
  valueSetterStatusAreYouSurePrompt2 (params) {
    const gridApi = GridHelpers.mgThisArray[0].gridApi
    const rowNode = gridApi.getRowNode(params.id)
    rowNode.setDataValue('state', '[[ALLOWED]]' + params.newValue)
  },

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // valueFormatters
  formatterColor: function (params) {
    if (params && params.data) {
      if (GridHelpers.mgThisArray[0].computeProductString) {
        return GridHelpers.mgThisArray[0].computeProductString(params.data.color, params.data, ['usFallback'])
      } else {
        return ''
      }
    } else {
      return ''
    }
  },
  formatterCurrencyPassthroughNoZero: function (params) {
    if (params.node.group === false && params.value) {
      return ColumnHelpers.formatterCurrency(params.value, true)
    } else {
      return ''
    }
  },
  formatterCurrencyPassthrough: function (params) {
    if (params.node.group === false && params.hasOwnProperty('value')) {
      return ColumnHelpers.formatterCurrency(params.value, false)
    } else {
      return ''
    }
  },
  formatterCurrency: function (value, nozero) {
    if (!value) {
      value = ''
    }
    // let val = value.toString().replace(/[^\d.-]/g, '')
    let val = ColumnHelpers.numberRemoveExtraneous(value)
    if ((val === '' || val === '0') && nozero) {
      return ''
    } else {
      if (!val) {
        val = 0
      }
      val = val.toFixed(GridHelpers.gridHelperSettings.decimalPlaces)
      return GridHelpers.gridHelperSettings.currencySymbol + val
    }
  },
  formatterNumberCommasPassthrough: function (params) {
    if (params.node.group === false && params.value) { // make zeros
      return ColumnHelpers.formatterNumberCommas(params.value)
    } else {
      return ''
    }
  },
  formatterNumberCommas: function (value) {
    // add commas
    let x = ColumnHelpers.numberRemoveExtraneous(value)
    x = x.toString()
    let pattern = /(-?\d+)(\d{3})/
    while (pattern.test(x)) {
      x = x.replace(pattern, '$1,$2')
    }
    return x
  },
  formatterPercentPassthrough: function (params) {
    if (params && params.node.group === false && params.hasOwnProperty('value')) { // non-zeros ok
      return ColumnHelpers.formatterPercent(params.value)
    } else {
      return ''
    }
  },
  formatterPercent: function (value) {
    // add percent sign, round to two places
    let ret = 'N/A'
    if (value) {
      value = value.toString().replace(/[^\d.-]/g, '')
      value = BigNumber(value).toFixed(2)
      if (!BigNumber(value).isNaN()) {
        ret = value + '%'
      }
    }
    return ret
  },
  // pass in YYYYMMDD, ex: 20201030
  formatterDatePassthroughYYYYMMDD_AddOneToMonth: function (params) {
    if (params.node.group === false && params.value) {
      // The original value
      let dateValue = params.value

      // Extract the year, month (zero-based), and day
      let year = parseInt(dateValue.substr(0, 4), 10)
      let monthZeroBased = parseInt(dateValue.substr(4, 2), 10)
      let day = parseInt(dateValue.substr(6, 2), 10)

      // Add one to the month (adjusting for the zero-based index)
      let month = monthZeroBased + 1

      // Ensure the month and day are two-digit strings with leading zeros
      let newMonth = month.toString().padStart(2, '0')
      let newDay = day.toString().padStart(2, '0')

      // Reconstruct the new date value with the updated month
      let newDateValue = year.toString() + newMonth + newDay

      return ColumnHelpers.formatterDate(newDateValue)
    }
  },
  formatterDatePassthroughYYYYMMDD: function (params) {
    if (params.node.group === false && params.value) {
      return ColumnHelpers.formatterDate(params.value)
    }
  },
  formatterDate: function (value, format = 'MM/DD/YY') {
    if (value) {
      return formatDateOnly(value, format)
    } else {
      return ''
    }
  },

  formatterDateTimePassthrough: function (params) {
    if (params.node.group === false && params.value) {
      return ColumnHelpers.formatterDateTime(params.value, false)
    }
  },
  formatterDateTimePassthroughPacific: function (params) {
    if (params.node.group === false && params.value) {
      return ColumnHelpers.formatterDateTime(params.value, true)
    }
  },
  formatterDateTime: function (value, forcePacific = false) {
    let date = new Date(value)
    if (isNaN(date) === false) {
      if (forcePacific) {
        dayjs.extend(utc)
        dayjs.extend(timezone)
        return dayjs(date).tz(ITS__TIMEZONE__DEFAULT).format('MM/DD/YY hh:mm a')
      } else {
        return dayjs(date).format('MM/DD/YY hh:mm a')
      }
    } else {
      return ''
    }
  },

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // valueGetters
  getterFeaturesLongNames: function (params) {
    let ret = ''
    if (params.node.group === false) {
      if (params.data) {
        let field = params.column.colDef.field
        if (params.data[field]) {
          let arr = params.data[field]
          if (Array.isArray(arr)) {
            for (let i = 0; i < arr.length; i++) {
              //  let longName = properties
              let val = arr[i]
              let longName = val
              if (val) {
                let longNameArr = properties.state.data.Product.properties.features.options.filter(x => x['value'] === val)
                if (longNameArr.length > 0) {
                  longName = longNameArr[0].label
                }
                if (ret !== '') {
                  ret += ', '
                }
                ret += longName
              }
            }
          }
        }
      }
    }
    return ret
  },
  getterInOrdersIds: function (params) {
    return params.data?.inOrders?.map(order => order.orderNumber) || []
  },
  getterArrayExplodePassthrough: function (params) {
    let ret = ''
    if (params.node.group === false) {
      if (params.data) {
        let field = params.column.colDef.field
        if (params.data[field]) {
          let val = params.data[field]
          ret = ColumnHelpers.getterArrayExplode(val)
        }
      }
    }
    return ret
  },
  getterArrayExplode: function (value) {
    // filter out blanks
    let ret = value
    if (Array.isArray(value)) {
      let filtered = value.filter(function (el) {
        return (el !== null && el !== '')
      })
      ret = filtered.join(', ')
    }
    return ret
  },

  // return US value, unless international then look for metric
  getterDivisionNamePassthrough: function (params) {
    let ret = ''
    if (!params.node.group) {
      if (params.data?.division) {
        ret = params.data.division
      } else if (params.data?.products?.division) {
        ret = params.data?.products?.division
      } else if (params.data?.divisionId) {
        ret = params.data?.divisionId
      }
      if (GridHelpers.mgThisArray[0]) {
        ret = pricesheet.getDivisionLongNameFromCode(ret, properties)
      }
    }
    return ret
  },

  //get warehouse - displays “region (need to translate region to the display name)  - origin display also translated to display” *you can see this being done in the first drop down of the create dialog
  getWarehouse: function (params) {
    // Extract single region and origin codes from params.data
    const inputRegion = params.data.region; // Region code
    const inputOrigin = params.data.origin; // Origin code

    // Access the wholesale properties map
    const wholesaleProperties = GridHelpers.mgThisArray[0].wholesaleProperties;

    // Find the matching region name
    const regionItem = wholesaleProperties?.region?.find(reg => reg.code === inputRegion);
    const regionName = regionItem ? regionItem.name : inputRegion; // Use name if found, otherwise fallback to code

    // Find the matching origin name
    const originItem = wholesaleProperties?.origin?.find(orig => orig.code === inputOrigin);
    const originName = originItem ? originItem.name : inputOrigin; // Use name if found, otherwise fallback to code

    // Return the formatted string
    return `${regionName} - ${originName}`;
  },

  getShipperNameFromCodePassthrough: function (params) {
    // set display to code and do a lookup to try to match code to name
    let shipperCode = (params.data?.products?.shipper) ? params.data?.products?.shipper : params.data?.shipper
    return ColumnHelpers.getShipperNameFromCode(shipperCode)
  },
  getShipperNameFromCode: function (shipperCode) {
    let ret = shipperCode
    const shippersObject = properties.state.data.Orders.default.shippersObject
    Object.values(shippersObject).forEach(function (obj) {
      if (obj.code === shipperCode) {
        ret = obj.name
      }
    })
    return ret
  },

  getterNewColorPassthrough: function (params) {
    let ret = ''
    if (params.node.group === false) {
      let carryover = params.data.carryover
      // if carry over is 0
      if (carryover === 1) {
        ret = 'No'
      } else if (carryover === 0) {
        // check other styles
        // if any has carryover=1, then yes -- otherwise they are ALL new colors - which means new style - so make it no
        let style = params.node.data.style
        let styleData = GridHelpers.returnMatchingStylesFromAssortment(style)
        let styleIsNew = true
        for (let i = 0; i < styleData.length; i++) {
          if (styleData[i]['carryover'] === 1) {
            styleIsNew = false
          }
        }
        if (styleIsNew) {
          ret = 'No' // style is nee, so everything no
        } else {
          ret = 'Yes' // style is old, but this color is new
        }
      }
    }
    return ret
  },

  getterShowTrueFalseAsYesNo: function (params) {
    let ret = ''
    if (params.node.group === false) {
      const field = params.colDef.field
      const val = params.data[field]
      ret = pricesheet.showTrueFalseAsYesNo(val)
    }
    return ret
  },

  // return US value, unless international then look for metric
  getterDimensionsPassthrough: function (params) {
    let ret = ''
    if (params.node.group === false) {
      if (params.data) {
        ret = params.data.dimensions
        if (GridHelpers.mgThisArray[0] && GridHelpers.mgThisArray[0].assortment?.locationId !== 'US') {
          if (params.data.metricDimensions) {
            ret = params.data.metricDimensions
          }
        }
      }
    }
    if (ret !== '') {
      ret = ColumnHelpers.getterArrayExplode(ret)
    }
    return ret
  },

  // return special capacity formatting
  getterCapacityPassthrough: function (params) {
    let ret = ''
    if (params.node.group === false) {
      if (params.data && params.data.capacityUnit) {
        ret = params.data.capacityUnit
        if (ret !== '' && params.data.capacityType !== '') {
          ret += ' ' + params.data.capacityType
        }
      }
    }
    return ret
  },

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  rendererLabelPassthrough: function (params) {
    if (params.node.group === false) {
      let labels = GridHelpers.mgThisArray[0].assortment?.labels
      if (labels) {
        let labelName = labels.filter(x => x['color'] === params.value)
        let labelNameDisplay = ''
        if (labelName.length > 0) {
          labelNameDisplay = labelName[0].name
        }
        return '<div class="label-selector"><div title="' + labelNameDisplay + '" class="color color--' + params.value + '"</div>'
      } else {
        return ''
      }
    } else {
      return ''
    }
  },
  rendererArchivedStatusPassthrough: function (params) {
    let ret = ''
    if (params.data.type === 'item') {
      let retName = ''
      let retIcon = ''
      let retMIClass = ''
      if (params.value) {
        retName = 'Archived'
        retIcon = 'toggle_off'
        retMIClass = 'material-icons'
      } else {
        retName = 'Active'
        retIcon = 'toggle_on'
        retMIClass = 'material-icons-outlined'
      }
      ret += '<span class="col-toggle">'
      ret += retName
      ret += ' <i class=\'' + retMIClass + '\'>' + retIcon + '</i>'
      ret += '</span>'
    }
    return ret
  },

  rendererUsePropertiesLabel: function (params) {
    let ret = ('')
    if (params.data) {
      ret = params.value
      let colId = params.colDef.field
      let list = PropertiesLookupLists.getPropertiesLookupList(colId)
      if (list) {
        let objArr = list.filter(x => x['value'] === ret)
        if (objArr.length > 0) {
          let obj = objArr[0]
          if (obj && obj.label) {
            ret = obj.label
          }
        }
      }
    }
    return ret
  },

  rendererShowFilePathPassthrough: function (params) {
    let ret = ''
    if (params.data.primarySourceFile) {
      let finalIndex = params.data.primarySourceFile.lastIndexOf('/')
      if (finalIndex) {
        ret = params.data.primarySourceFile.substr(0, finalIndex)
      }
    }
    return ret
  },
  getterCalculatedUnitsPassthrough: function (params) {
    let sizes = params && params.data && params.data.sizes;
    return ColumnHelpers.getterCalculatedUnits(sizes)
  },
  getterCalculatedUnits: function (sizes) {
    let total = 0;
    if (sizes) {
      let values = Object.values(sizes);

      for (let i = 0; i < values.length; i++) {
        let value = values[i];

        // Add only if value is a valid number and not null
        if (value !== null && typeof value === 'number') {
          total += value;
        }
      }
    }
    return total
  },

  getterCalculatedSubtotalPassthrough: function (params) {
    let total = 0;
    let sizes = params && params.data && params.data.sizes;

    if (sizes) {
      let values = Object.values(sizes);

      for (let i = 0; i < values.length; i++) {
        let value = values[i];

        // Add only if value is a valid number and not null
        if (value !== null && typeof value === 'number') {
          total += value;
        }
      }
    }

    let unitPrice = params.data.unitPrice
    if (!unitPrice) {
      unitPrice = 0
    }
    return total * unitPrice
  },

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // on cell clicked
  cellClickedArchived: function (params) {
    let newValue = (params.value === false)
    params.node.setDataValue('archived', newValue)
  },

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // comparators
  comparatorCustom: function (field, valueA, valueB, nodeA, nodeB, isInverted) {
    let sortObj = {}
    let sortItems = ColumnHelpers.customComparatorLists[field]
    // check for custom list
    try {
      if (GridHelpers.mgThisArray[0].assortment.uiSettings.gridSettings.customSort[field]) {
        // get custom list
        sortObj = GridHelpers.mgThisArray[0].assortment.uiSettings.gridSettings.customSort[field]
        let sortItemsTemp = cloneDeep(sortObj.items)

        // get all products, unique
        let products = GridHelpers.mgThisArray[0].assortment.products
        let productData = uniqBy(products, field)
        productData = sortBy(productData, field)
        productData = productData.map(function (row) {
          return row[field]
        })
        sortItems = union(sortItemsTemp, productData)

        // store here so we don't have to recalc
        if (sortItems) {
          ColumnHelpers.customComparatorLists[field] = sortItems
        }
      }
    } catch (error) {
    }

    if (sortItems) {
      // handle blanks better
      let index = sortItems.indexOf(undefined)
      if (index !== -1) sortItems[index] = ''
      index = sortItems.indexOf(null)
      if (index !== -1) sortItems[index] = ''
      if (!valueA) valueA = ''
      if (!valueB) valueB = ''

      // check for index
      let valueAIndex = sortItems.indexOf(valueA)
      let valueBIndex = sortItems.indexOf(valueB)

      // if both aren't indexed, then do alphabetical/numerical
      if (valueAIndex !== -1 && valueBIndex !== -1) {
        return valueAIndex - valueBIndex
      } else if (valueAIndex === -1 | valueBIndex === -1) {
        // if only one is not indexed, push that one down
        if (valueAIndex === -1) valueAIndex += 99999
        if (valueBIndex === -1) valueBIndex += 99999
        return valueAIndex - valueBIndex
      }
    }

    // if still haven't returned, do a normal alpha/numeric sort
    if (typeof valueA === 'string') {
      return valueA.localeCompare(valueB)
    } else {
      return (valueA - valueB)
    }
  },
  updateCustomComparator: function (field, items) {
    ColumnHelpers.customComparatorLists[field] = items
    GridHelpers.mgThisArray[0].$store.dispatch(VUEX_ASSORTMENT_GRID_SETTINGS_CUSTOMSORT_STATE_UPDATE, {
      field: field,
      items: items
    })
  },
  resetCustomComparator: function (field) {
    ColumnHelpers.customComparatorLists[field] = []
    GridHelpers.mgThisArray[0].$store.dispatch(VUEX_ASSORTMENT_GRID_SETTINGS_CUSTOMSORT_STATE_UPDATE, {
      field: field,
      items: []
    })
  },

  comparatorDateTime: function (valueA, valueB, nodeA, nodeB, isInverted) {
    let da = new Date(valueA).getTime()
    let db = new Date(valueB).getTime()
    return da - db
  },
  comparatorStyle: function (valueA, valueB, nodeA, nodeB, isInverted) {
    if (GridHelpers.mgThisArray[0].assortment.productType !== ITS__PRODUCT_TYPE__APPAREL) {
      if (nodeA.group && nodeB.group) {
        let childNodeA = nodeA.childrenAfterGroup[0]
        let childNodeB = nodeB.childrenAfterGroup[0]
        valueA = childNodeA.data.styleNumeric
        valueB = childNodeB.data.styleNumeric
      } else {
        valueA = nodeA.data.styleNumeric
        valueB = nodeB.data.styleNumeric
      }
    }

    if (typeof valueA === 'string') {
      return valueA.localeCompare(valueB)
    } else {
      return (valueA - valueB)
    }

    // return ?  :
  },

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // Cell Classes
  cellClassCostsCalculatedPassthrough: function (params) {
    if (params.node.group === false) {
      return ['cell-calculated-costs']
    }
  },
  cellClassCalculatedPassthrough: function (params) {
    if (params.node.group === false) {
      return ['cell-calculated']
    }
  },

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // Row Drag Text
  dragTextAssortment: function (params, dragItemCount) {
    if (dragItemCount > 1) {
      return dragItemCount + ' items being dragged'
    } else {
      return params.rowNode.data.style + ' ' + params.rowNode.data.color
    }
  },

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // Special read only / edit rules
  // make
  editableItemsYesFoldersNo: function (params) {
    if (params) {
      if (params.data && params.data.type === 'folder') {
        return false
      } else {
        return true
      }
    } else {
      return false
    }
  },
  cellClassItemsYesFoldersNo: function (params) {
    if (params.data && params.data.type === 'folder') {
      return 'cell-readonly'
    } else {
      return ''
    }
  },
  editableStyleColorNoExceptWhenDeleted: function (params) {
    if (params && params?.node?.group === false && !params.data.createdDate) {
      return true
    } else {
      return false // readonly
    }
  },
  cellClassStyleColorNoExceptWhenDeleted: function (params) {
    return (ColumnHelpers.editableStyleColorNoExceptWhenDeleted(params)) ? 'cell-deleted-highlight' : 'cell-readonly'
  },

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // CUSTOM MENU ITEMS
  // All columns that use getMainMenuItems will will run this column
  // Be sure to set:
  // suppressHeaderMenuButton: false,
  // menuTabs: [...], <<applicable tabs
  getMainMenuItems (params) {
    // let items = params.defaultItems
    let items = []
    if (!params?.column?.colDef?.suppressHeaderMenuButton) {
      items.push({
        name: 'Customize How This Column Sorts',
        action: function () {
          ColumnHelpers.customColumnSortingPopup(params)
        }
      })
    }
    return items
  },
  customColumnSortingPopup (params) {
    const colId = (params.column?.colDef?.field) ? params.column.colDef.field : params.colId
    const colTitle = (params.column?.colDef?.headerName) ? params.column.colDef.headerName : params.colTitle

    // if a custom coparater is added to use the list in view, then set list to null - otherwise, grab lookup list
    const useListInView = (params.column?.colDef?.comparatorParams?.useListInView || params.comparatorParams?.useListInView)
    let list = null
    if (!useListInView) {
      list = PropertiesLookupLists.getPropertiesLookupList(colId, false, true)
    }
    let title = 'Custom Column Sorting'
    let subtitle = `Change how the <b>${colTitle}</b> column is sorted for this assortment.`
    if (params.extras?.fromInternalAssortments) {
      // title = 'Cleanup Sorting'
      // subtitle = `Here you can order the <b>${colTitle.toLowerCase()}</b> column how you want.  It will also regroup rows and styles together.`
    }
    GridHelpers.mgThisArray[0].openDialog({
      content: '_core/Dialogs/Grids/Dialog_CustomColumnSort.vue',
      title: title,
      subtitle: subtitle,
      data: {
        colId: colId,
        colTitle: colTitle,
        list: list,
        extras: params.extras
      }
    })
  },

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // Special cost and quantity related assortment calculations
  getNetCost: function (params, format) {
    // return value
    let ret = ''

    // logic if either discountPercent or discountAmount is not blank
    let calculationType = ''
    if (params.data.discountPercent && isNaN(params.data.discountPercent) === false) {
      calculationType = 'discountPercent'
    } else if (params.data.discountAmount && isNaN(params.data.discountAmount) === false) {
      calculationType = 'discountAmount'
    }

    if (calculationType === '') {
      // if not calculated, return netCost - only if locations exists
      if (params.data.locations && params.data.netCost) {
        ret = ColumnHelpers.numberRemoveExtraneous(params.data.netCost)
      }
    } else {
      // grab starting cost
      let cost = Number(0)
      if (params.data.cost) {
        cost = ColumnHelpers.numberRemoveExtraneous(params.data.cost)
      }

      // calculation new price
      ret = cost
      if (calculationType === 'discountPercent') {
        let discountPercent = BigNumber(params.data.discountPercent)
        // let minus = BigNumber(cost).times(discountPercent).dividedBy(100)
        // ret = BigNumber(ret).minus(minus)
        ret = pricesheet.netCostFromDiscountPercentage(cost, discountPercent)
      } else if (calculationType === 'discountAmount') {
        let discountAmount = BigNumber(params.data.discountAmount)
        // ret = BigNumber(ret).minus(discountAmount)
        ret = pricesheet.netCostFromDiscountAmount(cost, discountAmount)
      }
      // if negative, set to blank
      if (ret < 0) {
        ret = ''
      }
    } // calculationType check

    // return formatted or raw
    if (format) {
      return ColumnHelpers.formatterCurrency(ret, true)
    } else {
      return ret
    }
  },

  getMarkupPercentPassthrough: function (params) {
    let suggestedRetail = 0
    if (params.data) {
      if (params.data.locations && params.data.suggestedRetail) {
        suggestedRetail = params.data.suggestedRetail
      }
    }
    suggestedRetail = parseFloat(suggestedRetail)

    let ret = ''
    if (suggestedRetail !== 0) {
      let netCost = ColumnHelpers.getNetCost(params, false)
      if (netCost > 0) {
        let pct = pricesheet.initialMarkup(netCost, suggestedRetail)
        if (Number(pct) === 0) {
          ret = ''
        } else {
          ret = ColumnHelpers.formatterPercent(pct)
        }
      }
    }
    return ret
  },
  setMarkupPercentPassthrough: function (params) {
    let ret = false

    let suggestedRetail = 0
    let markupPercent = params.newValue
    if (markupPercent === '') markupPercent = 0
    markupPercent = markupPercent / 100

    if (params.data.suggestedRetail) {
      suggestedRetail = Number(ColumnHelpers.numberRemoveExtraneous(params.data.suggestedRetail))
    }
    if (!Number.isFinite(suggestedRetail) || Number.isNaN(suggestedRetail)) {
      suggestedRetail = 0
    }
    if (suggestedRetail === 0) {
      ret = false
    } else {
      let netCost = suggestedRetail * (1 - markupPercent)
      params.node.setDataValue('netCost', netCost)
      ret = true
    }
    return ret
  },

  getsellThruPassthrough: function (params) {
    let ret = ''
    if (params.node.group === false) {
      let quantity = parseFloat(params.data.quantity)
      let onHand = parseFloat(params.data.quantityOnHand)
      if (quantity + onHand > 0) {
        ret = pricesheet.sellThrough(quantity, onHand)
        ret = ColumnHelpers.formatterPercent(ret)
      }
    }
    return ret
  },
  getStyleSellThruPassthrough: function (params) {
    let ret = ''
    if (params.node.group === false) {
      if (params.data.calculated_styleQuantity && params.data.calculated_styleQuantityOnHand) {
        let quantity = parseFloat(params.data.calculated_styleQuantity)
        let onHand = parseFloat(params.data.calculated_styleQuantityOnHand)
        if (quantity + onHand > 0) {
          ret = pricesheet.sellThrough(quantity, onHand)
          ret = ColumnHelpers.formatterPercent(ret)
        }
      }
    }
    return ret
  },

  getColorPercentagePassthrough: function (params) {
    let ret = ''
    if (params.node.group === false) {
      let quantity = parseFloat(params.data.quantity)
      let styleQuantity = parseFloat(params.data.calculated_styleQuantity)
      if (quantity > 0 && styleQuantity > 0) {
        ret = pricesheet.percentage(quantity, styleQuantity)
        ret = ColumnHelpers.formatterPercent(ret)
      }
    }
    return ret
  },

  getStyleQuantity: function (params) {
    return ColumnHelpers.sumAllValuesWithinStyle(params, 'quantity')
  },

  getStyleQuantityOnHand: function (params) {
    return ColumnHelpers.sumAllValuesWithinStyle(params, 'quantityOnHand')
  },
  sumAllValuesWithinStyle: function (params, colId) {
    let ret = ''
    if (params.node.group === false) {
      let style = params.node.data.style
      let styleData = GridHelpers.returnMatchingStylesFromAssortment(style)
      let styleQty = 0
      for (let i = 0; i < styleData.length; i++) {
        if (styleData[i][colId]) {
          let val = Number(styleData[i][colId])
          if (val > 0) {
            styleQty += val
          }
        }
      }
      if (styleQty > 0) {
        ret = styleQty
      }
    }
    // store to data for later potential use (like in getStyleSellThruPassthrough)
    let field = params.colDef.field
    if (params.node.data) {
      params.node.data[field] = ret
    }
    return ret
  },

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // Special Link Handling
  linkCellRenderer: function (params) {
    let disp = ''
    if (params.data && params.data.type === 'item') {
      disp = '<span class=\'link-holder\'>'
      disp += '<i class=\'material-icons\'>link</i>'
      disp += '</span>'
    }
    return disp
  },
  linkCellRendererText: function (params) {
    let disp = ''
    if (params.data && params.data.type === 'item') {
      if (Array.isArray(params.value)) {
        params.value.forEach((value, idx) => {
          disp += `<span data-index='${idx}' class='link-holder-text'>` // passing item index as data attribute
          disp += value
          disp += '</span> '
        })
      } else {
        disp = '<span class=\'link-holder-text\'>'
        disp += params.value
        disp += '</span>'
      }
    }

    return disp
  },
  linkOnCellClicked: function (params) {
    if (params.data.type === 'item') {
      GridHelpers.deselectAll()
      const orderSubtype = router?.currentRoute?.value?.meta?.orderSubtype

      if (orderSubtype === ITS__ORDERS__ORDER_TYPE__SAMPLE) {
        ColumnHelpers.gotoOrdersRoute(params.data.orderNumber, 'samples')
      } else if (orderSubtype === ITS__ORDERS__ORDER_TYPE__PROMO) {
        ColumnHelpers.gotoOrdersRoute(params.data.orderNumber, 'promos')
      } else if (orderSubtype === ITS__ORDERS__ORDER_TYPE__WHOLESALE) {
        ColumnHelpers.gotoOrdersRoute(params.data.orderNumber, 'wholesale')
      } else {
        ColumnHelpers.gotoAssortmentRoute(params.data._id)
      }
    }
  },
  linkOrderOnCellClicked (params) {
    if (params.data.type === 'item') {
      const index = params.event.target?.dataset?.index
      const value = Array.isArray(params.value) ? params.value[index] : params.value

      if (value === undefined) return
      ColumnHelpers.gotoOrdersRoute(value, 'samples')
    }
  },
  linkCellRendererTextExternal: function (params) {
    let disp = ''
    const field = params.colDef.field
    let url = ''
    switch (field) {
      case 'tracking':
      case 'products.tracking':
        const trackingId = (params.data?.products?.tracking) ? params.data?.products?.tracking : params.data?.tracking
        const shipper = (params.data?.products?.shipper) ? params.data?.products?.shipper : params.data?.shipper
        const trackingUrl = (params.data?.products?.trackingUrl) ? params.data?.products?.trackingUrl : params.data?.trackingUrl
        if (trackingUrl) {
          url = trackingUrl
        } else {
          url = ColumnHelpers.constructTrackingUrl(shipper, trackingId)
        }
        break
    }
    if (params.value) {
      if (url) {
        disp = `<a class="link-holder-text" href="${url}" target="_blank">
                ${params.value}
        </a>`
      } else {
        disp = params.value
      }
    }
    return disp
  },
  constructTrackingUrl (shipper, trackingId) {
    let url = ''
    let urlRoot = ''
    if (shipper && trackingId) {
      const shippersObject = properties.state.data.Orders.default.shippersObject
      Object.values(shippersObject).forEach(function (obj) {
        if (obj.code === shipper) {
          urlRoot = obj.url
        }
      })
    }
    if (urlRoot) {
      url = urlRoot + trackingId
    }
    return url
  },

  assortmentLinkOnCellDoubleClicked: function (params) {

  },

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // Special Star Handling
  starCellRenderer: function (params) {
    let disp = ''
    if (params.data && params.data.type === 'item') {
      disp = '<span class=\'star-holder\'>'
      if (params.value === true) {
        disp += '<i class=\'material-icons starred\'>star</i>'
      } else {
        disp += '<i class=\'material-icons\'>star_border</i>'
      }
      disp += '</span>'
    }
    return disp
  },
  starOnCellClicked: function (params) {
    // do this to better handle undefined
    let newValue = params.value !== true
    params.node.setDataValue('starred', newValue)
  },

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // My Assortments - Title - InnerRenderer
  treedataFileCellRenderer: function (columnDefinitionType = '') {
    let FileCellRenderer = function () {
    }
    FileCellRenderer.prototype.init = function (params) {
      if (params.data) {
        let tempDiv = document.createElement('div')
        let value = '<span class=\'row-title ' + params.data.type + '\'>'
        if (params.data && params.data.type === 'folder') {
          value += '<i class=\'material-icons folder\'>folder_open</i>'
        }
        value += '<span class="val">' + params.value + '</span>'
        if (params.data) {
          if (params.data.dynamic === 1) {
            value += '<i class=\'material-icons dynamic\'>flash_on</i>'
          }

          let updatesCount = 0
          if (params.data?.alerts) {
            updatesCount = params.data.alerts.length
          }

          if (updatesCount > 0) {
            // let tempFilter = 'collections_bookmark'
            let tempFilter = (updatesCount > 9) ? 'filter_9_plus' : 'filter_' + updatesCount
            value += '<i title=\'Note: New Status Change Alerts\' class=\'material-icons-outlined updates\'>' + tempFilter + '</i>'
          }
        }
        value += '</span>'
        tempDiv.className = 'folder-row-cell'
        tempDiv.innerHTML = value

        this.eGui = tempDiv

        // hyperlink text
        this.eButton = this.eGui.querySelector('.row-title.item .val')
        if (this.eButton) {
          this.clickListener = function (e) {
            if (params.data.type === 'item') {
              clearTimeout(ColumnHelpers.eButtonTimer)
              ColumnHelpers.eButtonTimer = setTimeout(function () {
                GridHelpers.deselectAll()
                if (columnDefinitionType === 'list_orders') {
                  ColumnHelpers.linkOnCellClicked(params)
                } else {
                  ColumnHelpers.gotoAssortmentRoute(params.data._id)
                }
              }.bind(ColumnHelpers, params), 200)
            }
          }
          this.doubleclickListener = function (e) {
            clearTimeout(ColumnHelpers.eButtonTimer)
          }
          this.eButton.addEventListener('click', this.clickListener.bind(ColumnHelpers, params))
          this.eButton.addEventListener('dblclick', this.doubleclickListener.bind(ColumnHelpers, params))
        }
      }
    }
    FileCellRenderer.prototype.getGui = function () {
      return this.eGui
    }
    return FileCellRenderer
  },
  treedataFileCellSetter: function (params) {
    // TODO - AG GRID HACK - IN FUTURE VERSIONS
    // once again, another wonderful ag grid tree data hack
    // this is because 26.1.0 introduced a breaking change
    // if i have a field name in auto-group, the inner cell renderer breaks
    // so i need to manually patch everything - can't rely on cellvalue changed stuff
    // this also breaks undo/redo for the cell value, since there is no reference to it

    // temp patch display
    params.data.title = params.newValue
    let hierarchyLength = params.data.hierarchy.length
    params.data.hierarchy[hierarchyLength - 1] = params.newValue
    params.colDef.field = 'title'

    // send it
    DataMiddleware.addNewChange(params, 'rowsUpdate')
  },
  gotoAssortmentRoute (assortmentId) {
    let pathSlug = 'assortment'
    if (router?.currentRoute?.value.meta?.manageType === ITS__LIBRARIES__MANAGE_TYPE__ASSORTMENTS__INTERNAL) {
      pathSlug = 'internal'
    }
    GridHelpers.mgThisArray[0].$store.dispatch(VUEX_ROUTING_ROUTE, {
      path: `/product/assortments/${pathSlug}/${assortmentId}`,
      query: {}
    })
  },
  gotoOrdersRoute (orderNumber, pathSlug = 'samples') {
    const path = `/orders/${pathSlug}/order/${orderNumber}`
    GridHelpers.mgThisArray[0].$store.dispatch(VUEX_ROUTING_ROUTE, {
      path: path,
      query: {}
    })
  },

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // Utility functions related to columns
  reformatDimensionsHeader: function (locationId, arr) {
    let cont = true
    if (locationId !== 'US') {
      for (let i = 0; i < arr.length; i++) {
        let item = arr[i]
        if (item.children) {
          for (let ii = 0; ii < item.children.length; ii++) {
            let item2 = item.children[ii]
            if (item2.field && item2.field === 'dimensions') {
              item2.headerName = properties.state.data.Product.properties.metricDimensions.shortLabel
              cont = false
              break
            }
          }
        }
        if (cont === false) {
          break
        }
      }
    }
    return arr
  },

  // pass in columns - hide them from array
  killColumnsBeforeLoad: function (arr, columnDefArray) {
    for (let i = 0; i < columnDefArray.length; i++) {
      let item = columnDefArray[i]
      if (item.children) {
        for (let ii = 0; ii < item.children.length; ii++) {
          let item2 = item.children[ii]
          for (let z = 0; z < arr.length; z++) {
            let colToHide = arr[z]
            if (item2.field && item2.field === colToHide) {
              item2.hide = true
            }
          }
        } // sub columns
      }// children
    }// main columns
    return columnDefArray
  },

  numberRemoveExtraneous: function (val) {
    let x = ''
    x = val.toString().replace(/[^0-9.-]+/g, '')
    // x = BigNumber(x).toFixed(GridHelpers.gridHelperSettings.decimalPlaces)
    x = BigNumber(x)
    if (isNaN(x)) x = ''
    return x
  },

  humanFileSize: function (bytes) {
    const thresh = 1024 // 1000 alternative
    if (Math.abs(bytes) < thresh) {
      return bytes + ' B'
    }
    // var units = si ? ['kB','MB','GB','TB','PB','EB','ZB','YB'] : ['KiB','MiB','GiB','TiB','PiB','EiB','ZiB','YiB'];const units =
    const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    let u = -1
    do {
      bytes /= thresh
      ++u
    } while (Math.abs(bytes) >= thresh && u < units.length - 1)
    return bytes.toFixed(1) + ' ' + units[u]
  },

  // toggle column visibiliy based on column ID
  toggleColumnVisible (field) {
    let vis = !ColumnHelpers.getColumnVisible(field)
    ColumnHelpers.setColumnVisible(field, vis)
  },

  // set column as visible - true or false
  setColumnVisible (field, show) {
    const t = GridHelpers.mgThisArray[0]
    if (t && t.gridApi) {
      t.gridApi.setColumnsVisible([field], show)
    }
  },

  // is column visible?
  getColumnVisible (field) {
    const t = GridHelpers.mgThisArray[0]
    return t.gridOptions.gridApi.getColumn(field).visible
  },

  selectColumn (colId) {
    // try to open parent - select column only works if a column is visible
    // epic amount of deep API code
    const t = GridHelpers.mgThisArray[0]
    const gridApi = t.gridApi
    const col = gridApi.getColumn(colId)
    const columnGroup = col.parent
    const groupName = columnGroup.providedColumnGroup.colGroupDef.headerName
    col.parent.setExpanded(true)
    const columnGroupState = gridApi.getColumnGroupState()
    const updatedGroupState = columnGroupState.map((groupState) => {
      if (groupState.groupId === groupName) {
        return { ...groupState, open: true }
      }
      return groupState
    })
    gridApi.setColumnGroupState(updatedGroupState)

    // now that it is opened, select column
    let rows = GridHelpers.getRowNodes()
    if (rows.length) {
      const t = GridHelpers.mgThisArray[0]
      t.gridApi.addCellRange({
        rowStartIndex: 0,
        rowEndIndex: rows.length,
        columnStart: colId,
        columnEnd: colId
      })
    }
  },

  // make a column editable or not editable
  setColumnEditable (colId, editMode = true) {
    const t = GridHelpers.mgThisArray[0]
    for (let i = 0; i < t.columnDefArray.length; i++) {
      let col = t.columnDefArray[i]
      if (col) {
        if (col?.children?.length) {
          for (let ii = 0; ii < col.children.length; ii++) {
            let col2 = col.children[ii]
            if (col2.field === colId) {
              col2.editable = editMode
            }
          }
        }
      }
    }
  },

  // MAKE ALL COLUMNS READ ONLY / EDIT MODE
  setEditModeInAllCells (editMode) {
    const t = GridHelpers.mgThisArray[0]

    for (let i = 0; i < t.columnDefArray.length; i++) {
      let col = t.columnDefArray[i]
      if (col) {
        if (col?.children?.length) {
          for (let ii = 0; ii < col.children.length; ii++) {
            let col2 = col.children[ii]

            // first check if editableOriginal is set
            if (!col2.hasOwnProperty('editableOriginal')) {
              col2.editableOriginal = col2.editable
            }
            // if true, go back to editableOriginal
            if (editMode) {
              col2.editable = col2.editableOriginal
            } else {
              col2.editable = false
            }

            // technically thumbnails use the cell editor to enter preview mode
            // we need thumbnail to be edited - because otherwise it is unclickable and can't get into "expanded mode"
            // regardless, when you close expanded mode, the editor cancels the edit action
            // thus, all thumbnails need to be technically editable and the aggrid edit function is overrided within my custom editor
            // whew :)
            if (col2.field === 'thumbnail') {
              col2.editable = true
            }
          }
        } else {
          // first check if editableOriginal is set
          if (!col.hasOwnProperty('editableOriginal')) {
            col.editableOriginal = col.editable
          }
          // if true, go back to editableOriginal
          if (editMode) {
            col.editable = col.editableOriginal
          } else {
            col.editable = false
          }

          if (col.field === 'thumbnail') {
            col.editable = true
          }
        }
      }
    }
  }
}
export default ColumnHelpers
